

















































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import ISOCode from "@/store/entities/containercontext/isocode";

class ContainerTypeRequest {
    sizeId: number | null;
}

@Component
export default class EditISOCOde extends AbpBase {
    private containerSizesList: any;
    private containerTypesList: any;

    @Prop({type: Boolean, default: false}) value: boolean;
    @Prop({type: Boolean, default: false}) public toEdit: boolean;

    isoCode: ISOCode = new ISOCode();
    request: ContainerTypeRequest = new ContainerTypeRequest();

    created() {
    }

    save() {
        (this.$refs.ISOCodeForm as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: this.toEdit ? 'isocode/update' : 'isocode/create',
                    data: this.isoCode
                });
                (this.$refs.ISOCodeForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    data() {
        return {
            containerSizesList: [],
            containerTypesList: [],
            updateCount: 0,
        }
    };

    async mounted() {
        this.containerSizesList = await this.$store.dispatch('containersize/lookUp');
    }

    async loadContainerTypes() {
        this.containerTypesList = await this.$store.dispatch('containertype/filteredLookUp', this.request);
    }

    async isContainerSizeChange(val: number) {
        (this.$refs.containerType as any).clearSingleSelect();
        if (val === 0)
            this.request.sizeId = null;
        else
            this.request.sizeId = val;
        await this.loadContainerTypes();
    }

    cancel() {
        (this.$refs.ISOCodeForm as any).resetFields();
        this.$emit('input', false);
    }

    async visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            if (this.toEdit) {
                this.isoCode = Util.extend(true, {}, this.$store.state.isocode.editISOCode);
                this.request.sizeId = this.isoCode.containerSizeId;
                await this.loadContainerTypes();
            }
            else {
                this.isoCode = new ISOCode();
            }
        }
    }

    checkContainerTypeInput(rule, value, callback) {
        if (value == null) {
            return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Container type'))));
        }
        return callback();
    }

    checkDoubleInput(rule, value, callback) {
        if (value == null) {
            return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Container type'))));
        }
        const parsed = parseFloat(value);
        if (isNaN(parsed)) {
            return callback(new Error(this.L('Enter a number')));
        }
        return callback();
    }

    isoCodeRule = {
        length: [{required: true, validator: this.checkDoubleInput, trigger: 'blur'}],
        width: [{required: true, validator: this.checkDoubleInput, trigger: 'blur'}],
        height: [{required: true, validator: this.checkDoubleInput, trigger: 'blur'}],
        code: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Code')), trigger: 'blur'}],
        containerTypeId: [{
            required: true,
            validator: this.checkContainerTypeInput,
            trigger: 'change'
        }],
    }
}
